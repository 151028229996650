import { HttpClient } from '@angular/common/http';
import { provideErrorHandler } from './_services/sentry-error-handler.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient);
}
const ɵ0 = provideErrorHandler;
export class AppModule {
}
export { ɵ0 };
