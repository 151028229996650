import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ReportsService } from '../../../_services/reports.service';
import { ApiService } from '../../../_interceptors/api.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
import { MultipleEmailsValidator } from '../../../_validators/multiple-emails';
const ɵ0 = APP_DATE_FORMATS;
export class ReportsSendToEmailComponent {
    constructor(reportsService, apiService, formsService, activatedRoute, formBuilder, previousUrlService, pageTitleService) {
        this.reportsService = reportsService;
        this.apiService = apiService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.report_id = null;
        this.report = null;
        this.dataLoaded = false;
        this.missingData = [];
        this.reportSendToClientForm = this.formBuilder.group({
            custom_contacts: ['', MultipleEmailsValidator()],
            report_format: ['pdf'],
            message: ['']
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    ngOnInit() {
        this.apiService.connectionChanged.subscribe(online => {
            if (online && this.missingData.length) {
                this.missingData = [];
                this.loadData();
            }
        });
        this.formsService.form = this.reportSendToClientForm;
        this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
            this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params) => {
            this.report_id = parseInt(params['report_id'], 10);
            this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_id + '/view');
            this.loadData();
        });
    }
    ngOnDestroy() {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    }
    loadData() {
        this.reportsService.getReport(this.report_id).then((details) => {
            if (typeof details !== 'undefined') {
                this.report = details;
                this.pageTitleService.setTitle(details.report_nr + ' | Send to email | ' + environment.webappName);
                this.pageTitleService.setPageTitle(details.report_nr);
                this.dataLoaded = true;
            }
            else {
                this.missingData.push('Rapportage');
            }
        });
    }
}
export { ɵ0 };
